export function anchorTags(parameters) {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
  }

  let selectors = parameters.selectors || "h1,h2,h3,h4,h5,h6";
  selectors = document.querySelector(parameters.scope).querySelectorAll(selectors);
  selectors.forEach((el, i) => {
    el.id = `h-${el.tagName}_${i}`;
  });
}

anchorTags({
  selectors: "h1,h2,h3,h4,h5,h6",
  scope: "body", 
});